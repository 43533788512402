import { graphql } from 'gatsby'
import CatalogPage from '../../components/CatalogPage'

export default CatalogPage

export const query = graphql`
  query Catalog {
    allWordpressWpProduct {
      nodes {
        id
        title
        link
        acf {
          alt_name
          price_before_text
          price
          price_after_text
          short_description
          is_hit
          is_new
          colors {
            name
            color
            image {
              localFile {
                childImageSharp {
                  fixed(width: 140, height: 140, quality: 95) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          }
          card_image {
            localFile {
              childImageSharp {
                fixed(width: 450, height: 215, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
